import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Result, Space, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined,SyncOutlined, UploadOutlined } from '@ant-design/icons';
import activityService from '../../services/activity.service';
import { selectPlanningSelected } from '../../redux/slices/app/planning.slice';
/* eslint-disable */
const ImportResume = ({ activityRequests, linkRequests }) => {
    const planningSelected = useSelector(selectPlanningSelected);
    const { t } = useTranslation('translation', { keyPrefix: 'modal_import' });

    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;

    const [resultIcon, setResultIcon] = useState(<UploadOutlined />);
    const [resultStatus, setResultStatus] = useState('info');
    const [errorText, setErrorText] = useState(generalTranslation('generic_error'));
    const [isImportOk, setIsImportOk] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const handleImportData = () => {
        setIsImporting(true)
        setResultIcon(<SyncOutlined spin />);
        activityService
        .importActivities(planningSelected.rootActivityId, activityRequests, linkRequests)
        .then(() => {
                setIsImporting(false)
                setResultIcon(<CheckCircleOutlined />);
                setResultStatus('success');
                setIsImportOk(true);
            })
            .catch((error) => {
                console.log("🚀 ~ handleImportData ~ error:", JSON.stringify(error))
                // TODO show summary of errors
                setErrorText(error.message)
                setIsImporting(false);
                setResultStatus('error');
                setResultIcon(<CloseCircleOutlined />);
            });
    };
    const extraContent = (
        <>
            {isImportOk ? (
                <Button type="primary" onClick={() => window.location.reload()}>
                    {t('reload')}
                </Button>
            ) : (
                <Button type="primary" disabled={isImporting} onClick={() => handleImportData()}>
                    {isImporting ? t('import_progress'): t('import')}
                </Button>
            )}
        </>
    );

    return (
        <Result
            status={resultStatus}
            icon={resultIcon}
            title={t('send_data')}
            subTitle={
                <>
                    <p>{t('activity_count')}: {activityRequests.length}</p>
                    <p>{t('link_count')}: {linkRequests.length}</p>
                </>
            }
            extra={extraContent}
        >
            {resultStatus === 'error' && (
                <Space direction="vertical">
                    <Typography.Text type="danger">{generalTranslation('generic_error')}</Typography.Text>
                    <Typography.Text type="danger">{errorText}</Typography.Text>
                </Space>
            )}
        </Result>
    );
};

export default ImportResume;
