import React, { useEffect, useState } from 'react';
import { isNull } from 'lodash';
import { GlobalOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { generateString } from '../../helpers/string-helper';

const LangageChooser = () => {
    const [selectedLangage, setSelectedLangage] = useState('fr');
    const { i18n } = useTranslation();

    useEffect(() => {
        const choosenLang = localStorage.getItem('lng');
        if (!isNull(choosenLang)) {
            setSelectedLangage(choosenLang);
        }
    }, []);

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lng', lang);
        setSelectedLangage(lang);
        window.location.reload(false);
    };
    const langItems = [
        {
            key: generateString(5),
            label: (
                <div  onClick={() => changeLanguage('fr')}>
                    
                    <span className={`${selectedLangage === 'fr' ? 'font-bold' : ''}`}>Français</span>
                </div>
            ),
        },
        {
            key: generateString(5),
            label: (
                <div onClick={() => changeLanguage('en')}>
                    <span className={`${selectedLangage === 'en' ? 'font-bold' : ''}`}>English</span>
                </div>
            ),
        },
    ];
    return (
        <Dropdown
            menu={{
                items: langItems,
            }}
            placement="bottomRight"
        >
                <Button size='small' className='flex items-center' icon={<GlobalOutlined />}>{selectedLangage?.toUpperCase()}</Button>
        </Dropdown>
    );
};

export default LangageChooser;
