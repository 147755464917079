import React, { useState, useEffect, useCallback } from 'react';
import { Button, List, Select, Table, Modal } from 'antd';
import { union } from 'lodash';
import { useSelector } from 'react-redux';
import * as moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { notificationError } from '../../helpers/notification';
import DefaultUnifiedFieldId from '../../constants/DefaultPlanningField';
import { generateString } from '../../helpers/string-helper';
import { ALL_JOBS } from '../../constants/Jobs';
import quantityUnits from '../plannings/activity_config';
import { selectCalendarEntities, selectTimeUnits } from '../../redux/slices/app/calendar.slice';

const TaskImport = ({ tasks=[], onOk, mappingData }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'modal_import' });
    const generalTranslation = useTranslation('translation', { keyPrefix: 'general' }).t;
    const columnTranslation = useTranslation('translation', { keyPrefix: 'columns' }).t;
    const activityTabTranslation = useTranslation('translation', { keyPrefix: 'activity_tab' }).t;
    const calendarsDictionary = useSelector(selectCalendarEntities);
    const timeUnits = useSelector(selectTimeUnits)
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(false);
    let mapping = mappingData;
    const [validationData, setValidationData] = useState([]);
    const generateCellId = (indexCol, indexRow) => `table-cell-${indexCol}-${indexRow}`;
    // Fields to choose for mapping
    const mappingColumns = [];
    window.ganttInstance.config.fullColumns
        .filter((column) => ['add', 'action_buttons', 'predecessors', 'successors'].indexOf(column.id) < 0)
        .forEach((val) => mappingColumns.push({ label: val.label, value: val.serverName }));

    // other columns
    mappingColumns.push({
        value: 'wbs',
        label: 'WBS',
    });
    mappingColumns.push({
        value: 'parentId',
        label: 'Parent',
    });
    mappingColumns.push({
        value: 'quantityUnit',
        label: columnTranslation('quantity_unit'),
    });
    mappingColumns.push({
        value: 'roundedDuration',
        label: activityTabTranslation('round_duration'),
    });
    mappingColumns.push({
        value: 'nope',
        label: columnTranslation('unassigned'),
    });
    mappingColumns.sort((a, b) => a.label.localeCompare(b.label));

    const computeColumns = (data) => {
        setLoading(true)
        let headers = [];
        const tableColumns = [
            {
                title: '',
                key: 'table-index',
                fixed: 'left',
                width: 50,
                align: 'center',
                render: (datac, r, index) => index + 1,
            },
        ];
        // getting all headers
        data.forEach((task) => {
            headers = union(headers, Object.keys(task));
        });
        // set default mapping
        const defaultMapping = {};
        headers.forEach(
            (header) => {defaultMapping[header] = mappingColumns.find((val) => val.label === header)?.value || 'nope'}
        );
        if (!mapping) {
            mapping = defaultMapping;
        }
        console.log('🚀 ~ computeColumns ~ mapping:', mapping);

        // generating columns
        tableColumns.push(
            ...headers.map((header, indexCol) => ({
                title: () => {
                    let mappingValue = 'nope';
                    if (mapping[header]) {
                        mappingValue = mapping[header];
                    }
                    return (
                        <div className="flex flex-col">
                            <span className="text-center">{header}</span>
                            <Select
                                defaultValue={mappingValue}
                                options={mappingColumns}
                                onChange={(val) => {mapping[header] = val}}
                            />
                        </div>
                    );
                },
                dataIndex: header,
                ellipsis: true,
                shouldCellUpdate: false,
                width: 200,
                render: (text, record, indexRow) => 
                        text !== '' && (
                            <span
                                className="p-1 inline-block w-full overflow-hidden text-ellipsis"
                                id={generateCellId(indexCol, indexRow)}
                            >
                                {text ? text?.toString() : text}
                            </span>
                        )
            }))
        );
        setLoading(false)
        return tableColumns;
    };
    useEffect(() => {
        setColumns(computeColumns(tasks));
    }, [tasks]);

    const calculUnifiedField = {
        duration: DefaultUnifiedFieldId.duration,
        quantity: DefaultUnifiedFieldId.quantity,
        workrate: DefaultUnifiedFieldId.yield,
    };

    const validateCellData = (field, mappingField, mappingLabel, data, cellId, indexRow) => {
        let hasError = false;
        switch (mappingField) {
            case 'champPMAutoId':
                if (data === undefined) {
                hasError = true;
                 }
                break;
            case 'quantityUnit':
                if (data !== '' && quantityUnits.findIndex((unit) => unit.value === data) === -1) {
                    hasError = true;
                }
                break;
            case 'calendarName':
                if (data !== '' && Object.values(calendarsDictionary).findIndex(({name}) => name === data ) === -1) {
                    hasError = true;
                }
                break;
            case 'dayDefinitionName':
                if (data !== '' && Object.values(timeUnits).findIndex(({name}) => name === data ) === -1) {
                    hasError = true;
                }
                break;
        
            default:
                break;
        }
        if (hasError) {
            const cell = document.getElementById(cellId);
            if (cell) cell.style.backgroundColor = 'orange';
            return {
                cellId,
                message: t('line_error', {index: indexRow, excelField: field, mappingField: mappingLabel})
            }
        }
        return undefined;
    }
    const verifyAndFormatData = useCallback(
        (data) => new Promise((resolve) => {
            setLoading(true)
            let canImport = true;
            console.log('🚀 ~ verifyAndFormatData ~ mapping:', mapping);
            const selectedColumns = Object.values(mapping);
            if (selectedColumns.indexOf('parentId') !== -1 && selectedColumns.indexOf('wbs') !== -1) {
                canImport = false;
                notificationError(t('data_import'), t('parent_error'));
            }
            const columnsToTest = selectedColumns.filter((i) => i !== 'nope');
            if (new Set(columnsToTest).size !== columnsToTest.length) {
                canImport = false;
                notificationError(t('data_import'), t('column_duplicate_error'));
            }
            
            if (!canImport) {
                setLoading(false)
                return;
            }

            /* eslint-disable */
            const formattedData = [];
            const error = [];
            data.forEach((item, indexLine) => {
                const customFields = [];
                const activityObj = {};
                let cellId;
                let currentColumn;
                Object.keys(mapping).forEach((i, indexCol) => {
                    if (mapping[i] && mapping[i] !== 'nope') {
                        if (mapping[i].indexOf('customField') !== -1) {
                            const valueToSend = item[i]?.replace(
                                generalTranslation('all_jobs', { lng: 'en' }),
                                ALL_JOBS
                            );
                            // const customFieldId = Number(mapping[i].split('#')[1]);
                            // const isJobField = planningCustomFields.find((i) => i.id === customFieldId)?.name === JOB_FIELD;
                            customFields.push({
                                id: Number(mapping[i].split('#')[1]),
                                value: valueToSend,
                            });
                        } else if (['startDate', 'endDate'].includes(mapping[i])) {
                            activityObj[mapping[i]] = moment(item[i]).utc(true).valueOf();
                        } else if (mapping[i] === 'champPMAutoId' ) {
                            activityObj[mapping[i]] = item[i] ? calculUnifiedField[item[i]?.toLowerCase()]: '';
                        } else {
                            activityObj[mapping[i]] = item[i];
                        }
                        currentColumn = i;
                    const currentMapping = mappingColumns.find((item) => item.value === mapping[i])?.label

                        cellId = generateCellId(indexCol, indexLine)
                        const dataValidation = validateCellData(currentColumn, mapping[i], currentMapping, activityObj[mapping[i]], cellId, indexLine + 1)
                        if (dataValidation) {
                            error.push(dataValidation)
                        } 
                    }
                });
                activityObj.customFields = customFields;
                formattedData.push(activityObj);
            });
            console.log('🚀 ~ data.forEach ~ formattedData:', formattedData);

            console.log("🚀 ~ TaskImport ~ validationData:", error)
            if (error.length > 0) {
                setValidationData(error);
                setLoading(false)
                Modal.confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: <p>{t('verification_error')}</p>,
                    okText: t('continue_anyway'),
                    cancelText: generalTranslation('cancel'),
                    
                    onOk: () => {
                      console.log('Continuer quand même', formattedData);
                        resolve ({ taskData: formattedData, mapping });
                    },

                    onCancel : () => {
                      console.log('Annuler');
                      return;
                    },
                  });
                // notificationError(
                //     t('data_import'),
                // );
            } else {
                setLoading(false)
                resolve ({ taskData: formattedData, mapping });
            }
            
        })
            ,
        [mapping]
    );
    const handleNextButtonClick = async () => {
        const importData = tasks.length > 0 ? await verifyAndFormatData(tasks) : { taskData: [], mapping };
        console.log('🚀 ~ handleNextButtonClick ~ importData:', importData);
        if (onOk && importData) {
            onOk(importData);
        }
    };

    const goToCell = (cellId) => {
        const element = document.getElementById(cellId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    };
    return (
        <div className="w-full h-full flex flex-col">
            <div className={`flex flex-grow  ${validationData.length > 0 ? 'h-40' : 'overflow-auto'}`}>
                <div className={`h-full ${validationData.length > 0 ? 'w-3/4  overflow-auto' : ''}`}>
                    <Table
                        sticky
                        bordered
                        pagination={false}
                        className="task-import-table"
                        size="small"
                        columns={columns}
                        dataSource={tasks}
                    />
                </div>
                {validationData.length > 0 && (
                    <div className="w-1/4 ml-1 overflow-auto">
                        <List
                            size="small"
                            header={<label>{t('verification_result')}</label>}
                            bordered
                            dataSource={validationData}
                            renderItem={(item) => (
                                <List.Item
                                    key={generateString(7)}
                                    className="hover:text-primary cursor-pointer"
                                    onClick={() => goToCell(item.cellId)}
                                >
                                    {item.message}
                                </List.Item>
                            )}
                        />
                    </div>
                )}
            </div>
            <div className="flex justify-end mt-2">
                <Button type="primary" loading={loading} onClick={() => handleNextButtonClick()}>
                    {t('verify_and_continue')}
                </Button>
            </div>
        </div>
    );
};

export default TaskImport;
