import React, {useState} from "react";
import { Steps } from "antd";
import TaskImport from "./TaskImport";
import LinkImport from "./LinkImport";
import ImportResume from "./ImportResume";
import { generateString } from "../../helpers/string-helper";
/* eslint-disable */
const ImportExcelStepper = ({importData}) => {
    const [current, setCurrent] = useState(0);
    const [taskMappingData, setTaskMappingData] = useState();
    const [taskDataToSend, setTaskDataToSend] = useState([]);
    const [linkMappingData, setLinkMappingData] = useState();
    const [linkDataToSend, setLinkDataToSend] = useState([]);
  const goToStep = (step) => {
    setCurrent(step);
  };

  const onTaskImportOk = ({mapping, taskData}) => {
    setTaskMappingData(mapping);
    setTaskDataToSend(taskData);
    goToStep(1);
  }
  const onLinkImportOk = ({mapping, linkData}) => {
    setLinkMappingData(mapping);
    setLinkDataToSend(linkData);
    goToStep(2);
  }
  const prev = () => {
    setCurrent(current - 1);
  };
  const next = () => {
    setCurrent(current + 1);
  };
    const steps = [
        {
          key: generateString(7),
          title: 'Données des activités',
          content: <TaskImport tasks={importData.tasks} onOk={onTaskImportOk} mappingData={taskMappingData}/>,
        },
        {
          key: generateString(7),
          title: 'Données des liens',
          content: <LinkImport links={importData.links} onOk={onLinkImportOk} mappingData={linkMappingData}/>,
        },
        {
          key: generateString(7),
          title: 'Envoi des données',
          content: <ImportResume activityRequests={taskDataToSend} linkRequests={linkDataToSend}/>,
        },
      ];
    return <div className="flex flex-grow flex-col h-full">
    <Steps className="my-1" size="small" current={current} items={steps} />
    <div className="steps-content flex-grow overflow-auto">{steps[current].content}</div>
    {/* <div className="steps-action">
      {current < steps.length - 1 && (
        <div className="flex justify-end">

        <Button type="primary" onClick={() => next()}>
          Next
        </Button>
        </div>
      )}
      {current === steps.length - 1 && (
        <Button type="primary">
          Done
        </Button>
      )}
      {current > 0 && (
        <Button
          style={{
            margin: '0 8px',
          }}
          onClick={() => prev()}
        >
          Previous
        </Button>
      )}
    </div> */}
  </div>
}

export default ImportExcelStepper;